import Home from '@views/Home.vue';
import Login from '@views/Login.vue';

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        //meta: { authorize: [ 'ADMINISTRATOR' ] },
    },
    {
        path: '/powerBi',
        name: 'PowerBi',
        component: () => import(/* webpackChunkName: "about" */ '@views/PowerBi.vue'),
        //meta: { authorize: [ 'ADMINISTRATOR' ] },
    },
    {
        path: '/about',
        name: 'About',
        meta: { authorize: [ 'ABOUT' ] },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '@views/About.vue')
    },
    {
        path: '/test',
        name: 'test',
        component: () => import(/* webpackChunkName: "about" */ '@views/Test.vue')
    },
    {
        path: '/login',
        name: 'Login',
        component: Login
    },
    {
        path: '/noauthorized',
        name: 'noauthorized',
        meta: { authorize: [ ] },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '@views/NoAuthorized.vue')
    },
];

export default routes