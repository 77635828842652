<style scoped>
a {
    color: #888;
    transition: all 0.8s;
}

a:hover {
    color: black;
    text-decoration: none;
}

input {
    background: transparent;
}

input::placeholder {
    color: #adadad;
}

.btn-inif {
    color: #fff;
    background: #000;
}

.btn-inif:hover,
.btn-inif:focus,
.btn-inif:active,
.open>.dropdown-toggle.btn-inif {
    background: #40bfff;
}

.btn-inif {
    color: #fff;
    background: #000;
}

.btn-inif:hover,
.btn-inif:focus,
.btn-inif:active,
.open>.dropdown-toggle.btn-inif {
    background: #40bfff;
}

.btn-outline-inif {
    color: #40bfff;
    border-color: #40bfff;
}

.btn-outline-inif:hover,
.btn-outline-inif:focus,
.btn-outline-inif:active,
.open>.dropdown-toggle.btn-outline-inif {
    color: #fff;
    background: #40bfff;
}
</style>

<template>
    <div class="Forgot">
        <h2>Olvido su clave</h2>
        <br />
        <b-alert :show="show" variant="danger"><b-icon icon="alert-square" scale="2"></b-icon><strong
                class="pl-2">ERROR!</strong> {{ error }}</b-alert>
        <br />
        <b-form v-if="reset" @submit.prevent.once="handleForgot">
            <b-form-group label="Email" class="prepend">
                <b-icon icon="person" scale="1.8"></b-icon>
                <input v-model="model.email" type="email" required placeholder="Ingrese su correo" />
            </b-form-group><br />
            <br /><br />
            <p class="text-muted">Se enviará un código a su correo, úselo como su clave para acceder/cambiar. Este
                código tiene una validez de 15 minutos.</p>
            <br /><br />

            <b-form-group>
                <b-button type="submit" pill block variant="outline-inif">Pedir código</b-button>
            </b-form-group>
            <b-link class="float-right" @click="$emit('forgot')">Regresar</b-link>
        </b-form>
    </div>
</template>

<script>
import settings from '@/global';
import userApi from '@services/userApiService';

export default {
    name: "ForgotPassword",

    data() {
        return {
            reset: true,
            show: false,
            user: null,
            model: { email: '', password: '@' },
            error: null,
            submitted: false
        };
    },

    computed: {
        loggingIn() {
            return JSON.parse(localStorage.getItem(settings.cache.user));
        }
    },

    methods: {
        async handleForgot() {
            this.show = false;
            this.submitted = true;

            //console.log('Handle');

            try {
                const r = await userApi.forgotPassword(this.model);
                const rest = r.data;
                //console.log(rest);

                if (rest.id == 1)
                    this.$emit('forgot');
            }
            catch (error) {
                this.show = true;
                this.error = error.response.data.message;
                this.reset = true;
                this.reset = false;
            }
        }
    }
}
</script>