<style scoped>
a {
  color: #888;
  transition: all 0.8s;
}

a:hover {
  color: black;
  text-decoration: none;
}

input::placeholder {
  color: #adadad;
}

input {
  background: transparent;
}

.code input {
  letter-spacing: 20px;
  font-size: 24px;
  font-weight: bold;
  border: none;
}

.code input:focus {
  outline: none;
}

.code .prepend input {
  text-indent: 48px;
}

.btn-inif {
  color: #fff;
  background: #000;
}

.btn-inif:hover,
.btn-inif:focus,
.btn-inif:active,
.open>.dropdown-toggle.btn-inif {
  background: #40bfff;
}

.btn-inif {
  color: #fff;
  background: #000;
}

.btn-inif:hover,
.btn-inif:focus,
.btn-inif:active,
.open>.dropdown-toggle.btn-inif {
  background: #40bfff;
}

.btn-outline-inif {
  color: #40bfff;
  border-color: #40bfff;
}

.btn-outline-inif:hover,
.btn-outline-inif:focus,
.btn-outline-inif:active,
.open>.dropdown-toggle.btn-outline-inif {
  color: #fff;
  background: #40bfff;
}

.login {
  position: relative;
  z-index: 100;
}
</style>

<template>
  <div>
    <div class="login" v-if="!twoFactor">
      <h2>Login</h2>
      <br />
      <b-alert :show="show" variant="danger"><b-icon icon="alert-square" scale="2"></b-icon><strong
          class="pl-2">ERROR!</strong> {{ error }}</b-alert>
      <br />
      <b-form v-if="!reset" @submit.prevent.once="handleSubmit">
        <b-form-group label="Email" class="prepend">
          <b-icon icon="person" scale="1.8"></b-icon>
          <input v-model="model.email" type="email" required placeholder="Ingrese su correo" /> </b-form-group><br />

        <b-form-group label="Clave" class="prepend mb-1">
          <b-icon icon="lock" scale="1.5"></b-icon>
          <input v-model="model.password" :type="passwordType" required placeholder="Ingrese su clave" />
        </b-form-group>
        <b-icon :icon="hover ? 'eye' : 'eye-slash'" style="float: right" @mouseover="hover = true"
          @mouseleave="hover = false"></b-icon>

        <br /><br />
        <b-link class="float-right" @click="$emit('forgot')">Olvido su clave?</b-link>
        <br /><br /><br />

        <b-form-group>
          <b-button type="submit" pill block variant="outline-inif" style="z-index: 100">Login</b-button>
        </b-form-group>
        <br />
      </b-form>
    </div>

    <div class="code" v-if="twoFactor">
      <h2>2FA Código</h2>
      <br />
      <b-alert :show="show" variant="danger"><b-icon icon="alert-square" scale="2"></b-icon><strong
          class="pl-2">ERROR!</strong> {{ error }}</b-alert>
      <br /><br />
      <b-form v-if="!reset" @submit.prevent="handleSubmitTwo">
        <b-form-group label="Código" class="prepend">
          <b-icon icon="chat-square-dots" scale="1.8"></b-icon>
          <input v-model="model.code" type="text" maxlength="5"
            required placeholder="*****" />
        </b-form-group>
        <p>Se le ha enviado un código a su correo el cual debe ingresar en este campo</p>
        <br />

        <br /><br />
        <br /><br /><br />

        <b-form-group>
          <b-button type="submit" pill block variant="outline-inif" style="z-index: 100">Verificar</b-button>
        </b-form-group>
        <br />
      </b-form>
    </div>
  </div>
</template>

<script>
import settings from "@/global";
import userApi from "@services/userApiService";

export default {
  name: "Login",

  data() {
    return {
      reset: false,
      show: false,
      user: null,
      error: null,
      submitted: false,
      model: { email: "", password: "" },
      hover: false,
      twoFactor: false,
      year: new Date().getFullYear(),
    };
  },

  computed: {
    loggingIn() {
      return JSON.parse(localStorage.getItem(settings.cache.user));
    },

    passwordType() {
      return this.hover ? "text" : "password";
    },
  },

  methods: {
    async handleSubmit() {
      this.show = false;

      try {
        const r = await userApi.login(this.model);
        const user = r.data || {};
        //console.log(r);

        if (user && user.id > 0) {
          this.twoFactor = true;
          //localStorage.setItem(settings.cache.user, JSON.stringify(this.user));
          //this.$emit("loggedIn", this.user);
        }
      } catch (error) {
        this.show = true;
        console.log(error);

        if ("message" in error) this.error = error.message;

        const hasResponse =
          "response" in error && error.response !== null && error.response !== undefined;
        if (hasResponse && error.response.status >= 400 && error.response.status < 500)
          this.error = error.response.data.message;

        if (hasResponse && error.response.status === 500)
          this.error = error.response.data.message;

        this.reset = true;
        this.reset = false;
      }
    },

    async handleSubmitTwo() {
      this.show = false;
      this.submitted = true;

      try {
        console.log("handleSubmitTwo");
        console.log(this.model);
        const r = await userApi.twoFactor(this.model);
        this.user = r.data || {};
        if (this.user && this.user.id > 0) {
          //this.user.roles = await userApi.getRoles(this.user.id);
          localStorage.setItem(settings.cache.user, JSON.stringify(this.user));
          this.$emit("loggedIn", this.user);
          return;
        }

        this.error = "Código incorrecto";
      } catch (error) {
        this.show = true;
        console.log(error);

        if ("message" in error) this.error = error.message;

        const hasResponse =
          "response" in error && error.response !== null && error.response !== undefined;
        if (hasResponse && error.response.status >= 400 && error.response.status < 500)
          this.error = error.response.data.message;

        if (hasResponse && error.response.status === 500)
          this.error = error.response.data.message;

        this.reset = true;
        this.reset = false;
      }
    },

    switchVisibility() {
      this.passwordType = this.passwordType === "password" ? "text" : "password";
    },
  },
};
</script>
