import axios from 'axios'
import settings from '@/global.js'
import cache from '@helpers/localCache'
import { authHeader } from '@helpers/authHeader'

const url = `${process.env.VUE_APP_BASE_URL}/City`;
authHeader(axios);

export default {
    async departmentsVersion() {
        const ret = await axios.get(`${url}/Departments/Version`);
        return new Date(ret.data).getTime();
    },

    async version() {
        const ret = await axios.get(`${url}/Version`);
        return new Date(ret.data).getTime();
    },

    async allDepartments() {
        const key = settings.cache.departments;
        const ver = await this.departmentsVersion();
        let dep = cache.get(key, ver);

        if(!dep) {
            const ret = await axios.get(`${url}/AllDepartments`);
            dep = ret.data;
            cache.set(key, dep, 0, ver)
        }

        return dep;
    },

    async allCities() {
        const key = settings.cache.cities;
        const ver = await this.version();
        let cities = cache.get(key, ver);

        if(!cities) {
            const ret = await axios.get(`${url}/AllCities`);
            cities = ret.data;
            cache.set(key, cities, 0, ver)
        }
        
        return cities;
    },

    async popularCities() {
        const key = settings.cache.popular;
        let cities = cache.get(key);

        if(!cities) {
            const ret = await axios.get(`${url}/Popular`);
            cities = ret.data;
            cache.set(key, cities, 60)
        }
        
        return cities;
    },

    async byId(id) {
        const key = settings.cache.cities;
        let cities = cache.get(key);

        if(cities)
            return cities.find(c => c.id === id);

        const ret = await axios.get(`${url}/GetById/?id=${id}`);
        return ret.data;
    },

    localId(id) {
        const key = settings.cache.cities;
        let cities = cache.get(key);

        return cities
            ? cities.find(c => c.id === id)
            : { name: "-" };
    },

    async search(filter) {
        /*const key = "SicoV2.1:cities";
        let cities = cache.get(key);

        if(cities)
            return cities.filter(c => c.name.localeCompare(filter));
        */
        const ret = await axios.get(`${url}/Search/?city=${filter}`);
        return ret.data;
    }
    //save(data) { return axios.post(`${url}/`, data); }
};