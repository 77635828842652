/**
 * Description.
 *   Landing page, mas adelante se definirá
 *
 * @date   2020-03-12
 */
// Libraries
import Vue from 'vue'

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'bootstrap/dist/css/bootstrap.css'

import moment from 'moment'
import 'moment/locale/es'
import numeral from 'numeral'
import numFormat from 'vue-filter-number-format'
import VueMoment from 'vue-moment'
import VueNumeric from 'vue-numeric'

// Modules
import router from '@routers/mainRouter'
// App
import App from '@/App.vue'

require('@plugins/fontawesome');

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueMoment, {moment});
Vue.use(VueNumeric);
Vue.use(numFormat);

Vue.filter('numFormat', numFormat(numeral));

Vue.config.devtools = true;
Vue.config.productionTip = false;
Vue.prototype.$scheduler = window.scheduler;

new Vue({
    router,
    render: h => h(App)
}).$mount('#app');
