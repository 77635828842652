<template>
  <Layout name="Login">
    <b-row>
      <b-col l="12">
        <Login v-if="!forgot" @loggedIn="logged" @forgot="forgot=!forgot"></Login>
        <Forgot v-if="forgot"  @forgot="forgot=!forgot"></Forgot>
      </b-col>
    </b-row>
  </Layout>
</template>

<script>
import settings from "@/global";
import Layout from "@layouts/Layout";
import Login from "@components/Login";
import Forgot from "@components/ForgotPassword";

import userApi from "@services/userApiService";

export default {
  components: {
    Layout,
    Login,
    Forgot,
  },

  data() {
    return {
      forgot: false,
    };
  },

  computed: {
    user () {
      return userApi.current();
    },
  },

  created() {
    localStorage.removeItem(settings.cache.user);
  },

  methods: {
    logged() {
      //console.log(`Logged (${this.user.changePassword})`);
      if(this.user && this.user.changePassword) 
        window.location = "admin.html";
      else this.$router.push("/");
    },
  }
};
</script>