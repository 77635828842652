<style>
.request-card-small {
    border-top: 3px solid skyblue;
    background-color: white;
    font-size: small;
    margin: 2px;
    float: none;
    margin-bottom: 6px;
}

.request-card-small .active {
    color: skyblue;
}
</style>

<template>
    <b-card no-body class="px-3 request-card-small">
        <b-row class="text-center">
            <b-col></b-col>
        </b-row>
        <b-row class="text-center text-middle bg-secondary text-white">
            <b-col>
                <!-- <span class="float-left" title="Creado">{{ request.created | moment("MMMDD") | uppercase }}</span> -->
                <strong>{{ request.id }}</strong>
                {{ processName(request.process) }}
            </b-col>
            <b-col class="text-right">
                <span class="date-pill text-dark bg-light text-middle" title="Expira"><b-icon icon="clock"></b-icon> {{
                        request.expirationDate | moment("YY.MMMDD")
                }}</span>
            </b-col>
        </b-row>
        <b-row class="text-center">
            <b-col>
                <label>{{ request.title }}</label>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="text-center bg-info text-white">
                <small>{{ name(line(request.lineOfId)) }} {{ name(coverage(request.coverageId)) }}</small>
            </b-col>
        </b-row>
        <b-row class="border-bottom">
            <b-col>
                <b-icon icon="phone" font-scale=".8" class="mr-2" title="Investigador"></b-icon>
                <small>{{ fullName(contact(request.responsibleId)) }}</small>
            </b-col>
            <b-col>
                <b-icon icon="people" font-scale=".8" class="mr-2" title="Analista"></b-icon>
                <small>{{ userBy(request.userId).fullName }}</small>
            </b-col>
        </b-row>
    </b-card>
</template>

<script>
import cityApi from '@services/cityApiService';
import clientApi from '@services/clientApiService';
import contactApi from '@services/contactApiService';
import coverageApi from '@services/coverageApiService';
import userApi from "@services/userApiService";

import RequestTypesEnum from "@enums/RequestTypesEnum";

const empty = { id: 0, name: "" };

export default {
    name: 'RequestCard',

    model: {
        event: ['newtask']
    },

    props: ['request', 'profile'],

    created() {
        cityApi.allCities();
        coverageApi.full();
        contactApi.all();
        clientApi.all();
        userApi.all();
    },

    computed: {
        user() {
            return userApi.current();
        },
    },

    methods: {
        // Formatters and render helpers
        client(id) {
            return id ? clientApi.byId(id) : empty;
        },

        line(id) {
            return coverageApi.lineOf(id) || empty;
        },

        coverage(id) {
            return coverageApi.coverage(id) || empty;
        },

        city(id) {
            return cityApi.localId(id);
        },

        contact(id) {
            return id ? contactApi.byId(id) : { partyTypeId: 1 };
        },

        userBy(id) {
            return id ? userApi.byId(id) : { partyTypeId: 1 };
        },

        fullName(val) {
            return val ? val.name + " " + val.family : "No definido";
        },

        name(val) {
            return val && val.name;
        },

        processName(idx) {
            const p = ['', 'CREADO', 'ASIGNACION', 'ELABORACION', 'EN PROCESO', 'ANALISIS', 'PRESENTAR', 'LIQUIDACION', 'FACTURACION', 'TERMINADO'];
            return (0 > idx || idx > 9) ? '' : p[idx];
        },

        processDesc(idx) {
            const pd = ['', 'El caso ha sido creado', 'Pendiente asignar un investigador a la tarea', 'El investigador esta recopilando información del caso', 'process', 'Pendiente por revision del analista', 'Presentar el informe al cliente', 'Realizar la liquidación de las tareas', 'Facturar las tareas', 'Caso cerrado'];
            return (0 > idx && idx > 9) ? '' : pd[idx];
        },

        type(id) {
            //console.log(id);
            return id == RequestTypesEnum.Tradicional ? ""
                : id == RequestTypesEnum.Hurto ? "HURTO"
                    : id == RequestTypesEnum.Daño ? "DAÑO"
                        : id == RequestTypesEnum.Persona ? "PERSONA"
                            : id == RequestTypesEnum.Juridico ? "JURIDICO" : "SOAT";
        }

    }
}
</script>