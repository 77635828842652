import axios from 'axios'
import settings from '@/global'
import cache from '@helpers/localCache'

const url = `${process.env.VUE_APP_BASE_URL}/Contact`;

export default {
    async  getAll() {
        return axios.get(`${url}/`);
    },

    async save(data) {
        return axios.post(`${url}/`, data);
    },

    async positionsVersion() {
        const ret = await axios.get(`${url}/Positions/Version`);
        return new Date(ret.data).getTime();
    },

    async version() {
        const ret = await axios.get(`${url}/Version`);
        return new Date(ret.data).getTime();
    },

    async allPositions() {
        const key = settings.cache.positions;
        const ver = await this.positionsVersion();
        let positions = cache.get(key, ver);

        if(!positions) {
            const ret = await axios.get(`${url}/AllPositions`);
            positions = ret.data;
            cache.set(key, positions, 0, ver)
        }

        return positions;
    },

    async all() {
        const key = settings.cache.contacts;
        const ver = await this.version();
        let contacts = cache.get(key, ver);

        if(!contacts) {
            const ret = await axios.get(`${url}/`);
            contacts = ret.data;
            cache.set(key, contacts, 120, ver)
        }

        return contacts;
    },

    positionById(id) {
        const key = settings.cache.positions;
        let positions = cache.get(key);

        return positions ? positions.find(u => u.id == id) : { name: "-" };
    },

    byPartyType(id) {
        const key = settings.cache.contacts;
        let contacts = cache.get(key);

        return contacts ? contacts.filter(c => c.partyTypeId == id) : [];
    },

    byParty(typeId, id) {
        const key = settings.cache.contacts;
        let contacts = cache.get(key);

        return contacts ? contacts.filter(c => c.partyTypeId == typeId && c.partyId === id) : [];
    },

    byId(id) {
        const key = settings.cache.contacts;
        let contacts = cache.get(key);

        return contacts ? contacts.find(u => u.id === id) : { name: "-" };
    },

    byUserId(id) {
        const key = settings.cache.contacts;
        let contacts = cache.get(key);

        return contacts ? contacts.find(u => u.userId === id) : { name: "-" };
    },

    findEmail(email){
        const key = settings.cache.contacts;
        let contacts = cache.get(key);
        return contacts ? contacts.find(c => c.email === email): [];
        // const ret = axios.get(`${url}/${email}`);
        // const res = ret.data;
        // return res;
    }
};

/*
function onError(error) {
    if (error.response.status === 401)
        localStorage.removeItem("user");
}

function onError(error) {
    if (error.response.status === 401)
        localStorage.removeItem("user");

    console.error('Request Failed:', error.config);
    if (error.response) {
        console.error('Status:', error.response.status);
        console.error('Data:', error.response.data);
        console.error('Headers:', error.response.headers);
    } else {
        console.error('Error Message:', error.message);
    }

    return Promise.reject(error.response || error.message);
}
*/