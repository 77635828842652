export function fnMsg (msg, title, variant, delay) {
    const isError = variant == "danger";
    const h = this.$createElement;
    const vNodesMsg = h(
        'p',
        { class: ['text-center', 'mb-0'] },
        [
            isError && h('b-spinner', { props: { type: 'grow', small: true } }),
            isError && h('strong', ` ERROR! `),
            msg + " ",
            isError && h('b-spinner', { props: { type: 'grow', small: true } })
        ]
    )
    this.$bvToast.toast(vNodesMsg, {
        title: title,
        autoHideDelay: delay || 5000,
        variant: variant,
        solid: true,
        //appendToast: append
    });
}
