import axios from 'axios'
import { authHeader } from '@helpers/authHeader'

const url = `${process.env.VUE_APP_BASE_URL}/Request`;
authHeader(axios);

export default {
    async all(page) {
        console.log("all", page);
        const ret = await axios.get(`${url}/All/?page=${page || 1}`);
        console.log(ret);
        return ret.data;
    },
    
    async resume(begin, end) {
        const ret = await axios.get(`${url}/Resume/?begin=${begin}&end=${end}`);
        return ret.data;
    },

    async downloadResume(begin, end) {
        axios({
          url: `${url}/ResumeDownload/?begin=${begin}&end=${end}`,
          method: 'GET',
          responseType: 'blob',
        }).then((response) => {
            var fileURL = window.URL.createObjectURL(new Blob([response.data]));
            var fileLink = document.createElement('a');
      
            fileLink.href = fileURL;
            fileLink.setAttribute('download', 'resume.xlsx');
            document.body.appendChild(fileLink);
      
            fileLink.click();
        });
      },
    
    async byClient(id) {
        const ret = await axios.get(`${url}/GetByClient/?id=${id}`);
        return ret.data;
    },

    async rateByClient(id) {
        const ret = await axios.get(`${url}/GetRateByClient/?id=${id}`);
        return ret.data;
    },

    async byResponsible(id) {
        const ret = await axios.get(`${url}/GetByResponsible/?id=${id}`);
        return ret.data;
    },

    async byProcess(process) {
        const ret = await axios.get(`${url}/GetByProcess/?process=${process}`);
        return ret.data;
    },

    async byProcessAndResponsible(process, responsibleId) {
        const ret = await axios.get(`${url}/GetByProcessAndResponsible/?process=${process}&responsibleId=${responsibleId}`);
        return ret.data;
    },

    async byUser(id) {
        const ret = await axios.get(`${url}/GetByUser/?userId=${id}`);
        return ret.data;
    },

    async juridical(id) {
        //console.log("byType");
        const ret = await axios.get(`${url}/GetJuridical/?typeId=${id}`);
        //console.log(ret);
        return ret.data;
    },

    async juridicalRevision() {
        //console.log("byType");
        const ret = await axios.get(`${url}/GetJuridicalRevision`);
        //console.log(ret);
        return ret.data;
    },

    async soat() {
        //console.log("byType");
        const ret = await axios.get(`${url}/GetSoat/`);
        //console.log(ret);
        return ret.data;
    },

    async byId(id) {
        //console.log(`GetById ${id}`);
        const ret = await axios.get(`${url}/GetById/?id=${id}`); //, {params: { id }});
        const req = ret.data;
        return req;
    },

    async fullById(id) {
        //console.log(`GetById ${id}`);
        const ret = await axios.get(`${url}/GetFullById/?id=${id}`); //, {params: { id }});
        const req = ret.data;
        return req;
    },

    async soatById(id) {
        //console.log(`GetById ${id}`);
        const ret = await axios.get(`${url}/GetSoatById/?id=${id}`); //, {params: { id }});
        const req = ret.data;
        return req;
    },


    async search(s) {
        const ret = await axios.post(`${url}/Search`, s); //, {params: { id }});
        const req = ret.data;
        return req;
    },

    async state(data) {
        console.log("state");
        console.log(data);
        const ret = await axios.post(`${url}/State`, data);
        return ret.data;
    },

    async exists(data) {
        const ret = await axios.post(`${url}/Exists`, data);
        return ret.data;
    },

    async save(data) {
        const ret = await axios.post(`${url}/`, data);
        return ret.data;
    },

    async analyze(data) {
        const ret = await axios.post(`${url}/Analyze/`, data);
        return ret.status === 200;
    },

    async present(data) {
        const ret = await axios.post(`${url}/Present/`, data);
        return ret.status === 200;
    },

    async liquidation(data) {
        const ret = await axios.post(`${url}/Liquidation/`, data);
        return ret.status === 200;
    },

    async ended(data) {
        const ret = await axios.post(`${url}/Ended/`, data);
        return ret.status === 200;
    },

/*
    delete(id) {
        return axios.post(`${url}/`, { id, active: false });
    }*/
};