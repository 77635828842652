<template>
    <Layout name="Default">
        <b-container>
            <b-row
                v-if="roles.some(r => r.id === 1 || r.name === 'ANALYST' || r.name === 'INVESTIGATOR' || r.name === 'ANALYSTBOSS')">
                <b-col l="12">
                    <div class="home">
                        <b-row>
                            <b-col class="lane">
                                <div class="title bg-danger text-white">
                                    Urgentes
                                </div>
                                <request-card-small v-for="request in highUrgency" :key="request.id" :request="request"
                                    @requestview="onRequestView(request.id)">
                                </request-card-small>
                            </b-col>
                            <b-col class="lane">
                                <div class="title bg-warning text-dark">
                                    Vence en 3 días
                                </div>
                                <request-card-small v-for="request in mediumUrgency" :key="request.id"
                                    :request="request" @requestview="onRequestView(request.id)">
                                </request-card-small>

                            </b-col>

                            <b-col class="lane">
                                <div class="title bg-success text-white">
                                    Vence en 5 días
                                </div>
                                <request-card-small v-for="request in lowUrgency" :key="request.id" :request="request"
                                    @requestview="onRequestView(request.id)">
                                </request-card-small>

                            </b-col>
                        </b-row>

                        <!-- Role:
                        <ul class="fact">
                            <li v-for="r in roles" :key="r.id">
                                Role: {{r.name}}
                            </li>
                        </ul>
                        
                        <p>Dos</p>

                        <ul class="fact">
                            <li v-for="r1 in roles1" :key="r1.id">
                                Role: {{r1.name}}
                            </li>
                        </ul> -->
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </Layout>
</template>

<script>
// @ is an alias to /src
import Layout from '@layouts/Layout';

import RequestCardSmall from '@components/RequestCardSmall.vue';
import crypto from '@helpers/crypto';
import requestApi from "@services/requestApiService";
import userApi from "@services/userApiService";

import { fnMsg } from '@helpers/message';

export default {
    name: 'Home',
    components: {
        Layout,
        RequestCardSmall
    },

    data() {
        return {
            loading: false,
            roles: [],
            records: [],
            needNewCredentials: false
        };
    },

    created() {

        this.load();
        //this.$eventBus.$on("request-process-changed", this.onProcessChanged);
        //this.$eventBus.$on("request-save", this.onRequestSave);
    },

    computed: {
        // new objects
        roles1() {
            const user1 = userApi.current();
            return (user1 && user1.roles) || [];
        },
        highUrgency() {
            return this.records.filter(r => this.getDaysDifference(r.expirationDate) < 1);
        },
        mediumUrgency() {
            return this.records.filter(r => this.getDaysDifference(r.expirationDate) < 3 & this.getDaysDifference(r.expirationDate) >= 1);
        },
        lowUrgency() {
            //console.log(this.records.filter(r => r.a=this.getDaysDifference(r.expirationDate)));
            return this.records.filter(r => this.getDaysDifference(r.expirationDate) >= 3);
        },


    },

    methods: {
        msgDlg: fnMsg,

        async load() {
            this.loading = true;
            const user = userApi.current();

            try {
                this.roles = await userApi.getRoles(user.id);
                const r0 = await requestApi.byUser(user.id);
                const r1 = await requestApi.byResponsible(user.contactId);
                const cat = [].concat(r0, r1);
                const requests = cat.filter((r, index, self) => self.findIndex(t => t.id === r.id) === index);
                this.records = requests.filter(r => r.process < 6);
                //this.tasks = await taskApi.byRequest(this.id);
                //this.sections = await sectionApi.configByRequest(this.id);
                this.loading = false;
                setTimeout(this.checkPowerBi, 1000);
            } catch (error) {
                const isResponse = 'response' in error

                if (!isResponse)
                    this.msgDlg(error, `General - ${this.$moment().format('HH:mm:ss')}`, "danger", 15000);

                if (isResponse && error.response.status == 401)
                    this.requestCredentials(error);
            }
        },
        getDaysDifference(date) {
            return (new Date(date).getTime() - Date.now()) / (1000 * 3600 * 24)
        },
        onRequestView(id) {
            this.$router.push('/request/manage/' + encodeURIComponent(crypto.encrypt(id.toString())));
        },
        checkPowerBi() {
            const user = userApi.current();
            if (user.roles.some(r => r.name === 'POWERBI')) {
                this.$router.push('PowerBi');
            }
        },
        requestCredentials(error) {
            const need =
                error.response.status === 401 || error.response.status === 400;
            this.needNewCredentials = need;
        }
    }
}
</script>
