import axios from 'axios'
import settings from '@/global.js'
import cache from '@helpers/localCache'
import { authHeader } from '@helpers/authHeader'

const url = `${process.env.VUE_APP_BASE_URL}/Coverage`;
authHeader(axios);

export default {
    async version() {
        const ret = await axios.get(`${url}/Version`);
        return new Date(ret.data).getTime();
    },

    async full() {
        const key = settings.cache.coverages;
        const ver = await this.version();
        let coverages = cache.get(key, ver);

        if(!coverages) {
            const ret = await axios.get(`${url}/Full`);
            coverages = ret.data;
            cache.set(key, coverages, 0, ver)
        }

        return coverages;
    },

    async  all() {
        //return axios.get(`${url}/`);
        const ret = await axios.get(`${url}/`);
        return ret.data;
    },

    async save(data) {
        const ret = await axios.post(`${url}/`, data);
        return ret.data;
    },

    lineOf(id) {
        const key = settings.cache.coverages;
        let lines = cache.get(key);

        return lines
            ? lines.find(l => l.id === id)
            : { name: "-" };
    },

    lineFromCoverage(id) {
        const cov = this.coverage(id);
        return this.lineOf(cov.lineOfId);
    },

    coverage(id) {
        const key = settings.cache.coverages;
        let line = cache.get(key);

        return line
            ? line.flatMap(i => i.coverages).filter(p => p).find(i => i.id === id)
            : { name: "-" };
    }
};