import axios from 'axios'
import settings from '@/global.js'
import cache from '@helpers/localCache'

import { authHeader } from '@helpers/authHeader'

const url = `${process.env.VUE_APP_BASE_URL}/Client`;
authHeader(axios);

export default {
    async version() {
        const ret = await axios.get(`${url}/Version`);
        return new Date(ret.data).getTime();
    },

    async all() {
        const key = settings.cache.clients;
        const ver = await this.version();
        let clients = cache.get(key, ver);

        if(!clients) {
            const ret = await axios.get(`${url}/`);
            clients = ret.data;
            cache.set(key, clients, 0, ver)
        }
        return clients;
    },

    async save(data) {
        // return axios.post(`${url}/`, data);
        const ret = await axios.post(`${url}/`, data);
        return ret.data;
    },

    delete(id) {
        return axios.post(`${url}/`, { id, active: false });
    },

    byId(id) {
        const key = settings.cache.clients;
        let clients = cache.get(key);
        return clients ? clients.find(c => c.id === id) : { name: "-" };
    },
    
    byCode(code) {
        console.log("code");
        const key = settings.cache.clients;
        let clients = cache.get(key);
        
        return clients ? clients.find(c => c.code === code) : { name: "-" };
    }
};